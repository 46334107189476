.hide-text {
    font: 0/0 serif;
    text-shadow: none;
    color: transparent;
}

.vertical-align {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}

.shadow {
	box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
}

.sect-hglt {
	background: $soft-gray;
}

.btn-lg-pd {
	padding-top: 40px;
}