.product {
    padding-top: 30px;
    @include breakpoint(large) {
        padding-top: 65px;
    }
}

.cart, .checkout {
    padding-top: 150px;
}

#prod-main-img {
    padding-bottom: 25px;
}

#cat-tease {
    padding-top: 30px;
}

.mark {
    margin: 0 auto;
    display: block;
    width: 40px;
}

#masthead {
    padding: 20px 15px 20px 30px;
    background: $white;
}

#logo {
    background: url("../_tmp/masthead.svg") no-repeat;
    margin: 0;
    width: 110px;
    height: 22px;
    transition: all .3s ease;
    @include breakpoint(large up) {
        width: 232px;
        height: 48px;
    }
}

#login-form {
    input[type="text"] {
        margin-bottom: 1rem;
    }
}

#cart {
    position: relative;
    .ct-count {
        position: absolute;
        top: -8px;
        right: -5px;
        background: map-get($foundation-palette, primary);
        color: $white;
        border-radius: 50%;
        width: 16px;
        height: 16px;
        z-index: 2;
        text-align: center;
        font-size: 12px;
        line-height: 14px;
        text-align: center;
    }
}

#sign-in {
    .fa-circle-thin, .fa-user {
        color: $medium-gray;
    }
    color: $dark-gray;
}

.prod-tease {
    a {
        color: $black;
    }
    figure {
        margin: 0 0 3em;
        figcaption {
            padding-top: 1em;
        }
    }
}

.divider {
    width: 75px;
    border-top: 1px dotted map-get($foundation-palette, primary);
    margin: 14px auto 10px;
}

// Global nav

#glb-nav {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 99;
    #main-nav a {
        color: $dark-gray;
        font-size: .6em;
        &:hover {
            color: map-get($foundation-palette, primary);
        }
        @include breakpoint(medium only) {
            padding-right: 5px;
        }

        font-weight: 700;
        letter-spacing: 2px;
        text-transform: uppercase;
    }
    #logo-link {
        padding: 0;
    }
}

.fa-circle {
    color: $medium-gray;
}

.headroom {
    transition: transform $mm_transitionDuration $mm_transitionFunction;
    z-index: -1;
}

.headroom--pinned {
    -webkit-transform: translateY(0%);
    -ms-transform: translateY(0%);
    transform: translateY(0%);
}

.headroom--unpinned {
    -webkit-transform: translateY(-100%);
    -ms-transform: translateY(-100%);
    transform: translateY(-100%);
}

.pg-sect {
    padding: 30px 0;
}

#ft-note {
    padding-top: 20px;
}

// Prevent the FOUC on the nav
.no-js {
    @include breakpoint(small only) {
        #dt-nav {
            display: none;
        }
    }

    @include breakpoint(medium) {
        .title-bar {
            display: none;
        }
    }
}

.event-item {
    .event-info {
        position: relative;
        padding: 15px 0 25px;
        @include breakpoint(medium) {
            padding: 40px 0 0 0;
        }
    }
}

footer {
    a {
        color: $dark-gray;
        font-size: .8em;
    }
    ul#social-list {
        margin: 0;
        text-align: center;
        li {
            display: inline-block;
            &:not(:first-child) {
                padding-left: 15px;
            }
        }
        list-style-type: none;
        img {
            width: 20px;
            height: 20px;
        }
    }
}

.page-hero {
    position: relative;
    height: 60vh;
    h3 {
        background: rgba(0,0,0,.3);
        padding: 15px;
        color: $white;
        letter-spacing: 3px;
        position: absolute;
        bottom: 25px;
        left: 25px;
    }
}

#category-hero {
    background: url("../_tmp/cat_hero.jpg") no-repeat;
    background-size: cover;
    background-position: center;
}

#about-hero {
    background: url("../_tmp/about-hero.jpg") no-repeat;
    background-size: cover;
    background-position: top;

}

#events-hero {
    background: url("../_tmp/events-hero.jpg") no-repeat;
    background-size: cover;
    background-position: center;

}

#contact-hero {
    background: url("../_tmp/contact-hero.jpg") no-repeat;
    background-size: cover;
    background-position: center;
}
