.js {
	font-family: 'Josefin Slab', serif;
	font-weight: 700;
}

.js-lt {
	@extend .js;
	font-weight: 400;
}

.san-bd {
	font-family: 'Raleway', sans-serif;
	font-weight: 700;
}

.nw {
	white-space: nowrap;
}

.san-lgt {
	font-weight: 400;
}

p {
	font-size: .8em;
	font-weight: 700;
}

// Helpers

.caps {
	text-transform: uppercase;
}

.mg-btm {
	margin-bottom: 10px;
}

.mg-bt-lg {
	margin-bottom: 40px;
}

.trk {
	letter-spacing: 4px;
}

.trk-sm {
	letter-spacing: 2px;
}

.no-mg-bd {
	margin-bottom: 0;
}

.mg-r {
	margin-right: 10px;
}

.no-pd-bd{
	padding-bottom: 0;
}

.lt {
	color: $dark-gray;
}

.quiet {
	color: #9B9BA3;
}

.hglt, a.hglt {
	color: map-get($foundation-palette, primary);
}

.sm-hdr {
	font-size: .75em;
	border-bottom: 1px solid $medium-gray;
	padding-bottom: rem-calc(10);
}



// Placeholder text
input::-webkit-input-placeholder {
   color: $dark-gray;
   font-size: .8em;
   font-weight: 700;
}

input:-moz-placeholder { /* Firefox 18- */
   color: $dark-gray;  
   font-size: .8em;
   font-weight: 700;
}

input::-moz-placeholder {  /* Firefox 19+ */
   color: $dark-gray;  
   font-size: .8em;
   font-weight: 700;
}

input:-ms-input-placeholder {  
   color: $dark-gray;  
   font-size: .8em;
   font-weight: 700;
}