.nav-icon {

    line-height: 2.7;
    color: $dark-gray;
    padding-right: 25px;
    @include breakpoint(large up) {
        line-height: 4.2;
    }
}

.nav-txt {
	font-size: .6em;
	letter-spacing: 2px;
	text-transform: uppercase;
}

#mobile-masthead {
	background: url('../_tmp/masthead.svg') no-repeat;
	width: 132px;
	height: 25px;
	display: block;
	top: 11px;
}