/*
	jQuery.mmenu borderStyle extension CSS
*/

@import "../../css/_imp/import";


.mm-menu.mm-border-none .mm-listview > li,
.mm-listview.mm-border-none > li
{
	&:after
	{
		content: none;
	}
}

.mm-menu.mm-border-full .mm-listview > li,
.mm-listview.mm-border-full > li
{
	&:after
	{
		left: 0 !important;
	}
}

.mm-menu.mm-border-offset .mm-listview > li,
.mm-listview.mm-border-offset > li
{
	&:after
	{
		right: $mm_listitemIndent;
	}
}