#fnt-carousel {
    position: relative;
    ul.slides {
        li {
            height: 80vh;
            background-size: cover !important;
            background-position: center !important;
        }
    }
    &.flexslider {
        border: none;
    }
    #hero-box {
    	transition: padding .5s ease;
        border: 15px solid rgba(255, 255, 255, 0.4);
        color: $dark-gray;
        position: absolute;
        top: 60%;
        -webkit-transform: translateY(-55%);
        transform: translateY(-55%);
        left: 0;
        right: 0;
        margin: auto;
        width: 90%;
        z-index: 2;
        background-color: $white;
        background-clip: padding-box;
        background-image: url('../_tmp/stamp.png');
        background-repeat: no-repeat;
        background-size: 185px;
        background-position: 100% -20px;
        padding: 15px;
        text-align: center;
        p {
            font-size: 1em;
        }
        @include breakpoint(medium up) {
        	width: 65%;
        	padding: 35px;
            border-width: 30px;
        }
        @include breakpoint(large) {
            width: 500px;
        }
    }
}

.thumbs {
    margin-left: 0;
    list-style: none;
    li {
        display: inline;
        img {
            margin: 10px 10px 0 0;
        }
    }
}

// old defaults
// border: 15px solid rgba(255,255,255,.4);
// color: $dark-gray;
// position: absolute;
// bottom: 35px;
// left: 30px;
// max-width: 38%;
// z-index: 2;
// background-color: $soft-gray;
// background-clip: padding-box;
// padding: 15px;

// old medium down
// max-width: 85%;
// top: 50%;
// -webkit-transform: translateY(-50%);
// transform: translateY(-50%);
// left: 0;
// right: 0;
// bottom: auto;
// margin: auto;