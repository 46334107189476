//	Borders
@mixin mm_border( $border, $pseudo, $pos1, $pos2, $pos3 )
{
	&:#{$pseudo}
	{
		content: '';
		border-#{$border}-width: 1px;
		border-#{$border}-style: solid;
		display: block;
		position: absolute;
		#{$pos1}: 0;
		#{$pos2}: 0;
		#{$pos3}: 0;
	}
}
@mixin mm_border_top
{
	@include mm_border( "top", "before", "left", "right", "top" );
}
@mixin mm_border_right
{
	@include mm_border( "right", "after", "top", "bottom", "right" );
}
@mixin mm_border_bottom
{
	@include mm_border( "bottom", "after", "left", "right", "bottom" );
}
@mixin mm_border_left
{
	@include mm_border( "left", "before", "top", "bottom", "left" );
}


//	Misc
@mixin mm_ellipsis()
{
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}
@mixin mm_clearfix()
{
	&:after
	{
		content: '';
		display: block;
		clear: both;
	}
}